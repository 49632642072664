<template>
  <MainLayout>
    <ContentCard title="Personal Information">
      <div class="p-4">
        <ToznyInput name="email" label="Email" id="email" type="email" disabled :value="identity.config.username" />
      </div>
    </ContentCard>
  </MainLayout>
</template>

<script>
import { mapState } from 'vuex'

import MainLayout from '@/Common/MainLayout/MainLayout'
import ContentCard from '@/Common/ContentCard'
import ToznyInput from '@/Common/ToznyInput'
export default {
  name: 'Profile',
  components: {
    MainLayout,
    ContentCard,
    ToznyInput,
  },
  computed: {
    ...mapState(['identity']),
  },
}
</script>
